// xiaqu.js
import { getPlatformPath } from "@/api/home";

const state = {
  xiaquCode: "430000",
  xiaquName: "八方电子招投标平台",
  address: "湖南省长沙市雨花区汇金路长沙屿A2栋9楼",
  region: "430000",
  siteDescription: "湖南省",
  siteName: "",
  siteTitle: "八方电子招投标平台",
  technicalSupport: "",
  keywords: "湖南群伦八方产权，湖南群伦八方产权中心，产权服务",
  description:
    "湖南群伦八方产权中心提供产权交易服务，交易方式包括网络竞价出让和招标出让",
  homePagePath: '',
  platformLogoPath: ''
};

const getters = {};

const mutations = {
  // 更新state数据
  updateState(state, data) {
    state.xiaquName = data.platformName;
    state.siteTitle = data.platformName;
    state.siteName = data.platformName;
    state.technicalSupport = data.platformName;
    state.homePagePath = data.homePagePath;
    state.platformLogoPath = data.platformLogoPath;
  },
};
const actions = {
  // 异步请求站点数据
  async getSiteInfo({ commit }) {
    await getPlatformPath({ path: window.location.origin }).then((res) => {
      console.log(res, '动态参数');

      if (res.data) {
        commit("updateState", res.data);
        console.log(state, 'state');
        // document.getElementById("keywords").content = res.data.siteDescription;
        // document.getElementById("description").content = res.data.siteDescription;
      }
    }).catch((e) => { });
  },
};
// 注意和仓库的区别
const store = {
  // namespaced用于在全局引用此文件里的方法时标识这一个的文件名，使得让人明白这些数据来自哪个仓库
  // 即当你需要在别的文件里面使用子仓库(mapStates、mapGetters、mapActions)时，里面的方法需要注明来自哪一个模块的方法
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
export default store;
