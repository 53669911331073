import { Notification } from "element-ui";
import router from "@/router";
import store from "@/store";

const state = {
  ws: null,
  heartCheckTimer: null,
  isActiveDisconnect: false, // 添加一个标志，用于判断是否是主动断开
};

const mutations = {
  SET_WS(state, ws) {
    state.ws = ws;
    console.log("SET_WS", state.ws);
  },
  CLEAR_WS(state) {
    state.ws = null;
  },
  SET_HEART_CHECK_TIMER(state, timer) {
    state.heartCheckTimer = timer;
  },
  SET_IS_ACTIVE_DISCONNECT(state, value) { // 添加一个 mutation 来设置主动断开标志
    state.isActiveDisconnect = value;
  }
};

// 解析地址栏参数
function getUrlParameters(url) {
  const params = new URLSearchParams(new URL(url).search);
  const parameters = {};
  for (const [key, value] of params.entries()) {
    parameters[key] = value;
  }
  return parameters;
}

const actions = {
  startWebSocket({ commit, dispatch, state }) {
    let projectId = router.history.current.query.projectId;
    let bidId = router.history.current.query.bidId;
    let projectType = router.history.current.query.projectType;
    let userId = store.state.user.info.id;

    // 获取地址栏参数
    const parameters = getUrlParameters(window.location.href);

    if (!state.ws || state.ws.readyState !== WebSocket.OPEN) {
      let socketUrl;
      if (window.location.protocol == "https:") {
        socketUrl = `wss:${location.host}/socket/websocket/${userId}/${projectId}/${bidId}/${projectType}/${parameters.sfEcbj || 0}`;
      } else {
        socketUrl = `ws:${location.host}/socket/websocket/${userId}/${projectId}/${bidId}/${projectType}/${parameters.sfEcbj || 0}`;
      }

      // socketUrl = `ws://192.168.1.113:6009/socket/websocket/${userId}/${projectId}/${bidId}/${projectType}`;
      console.log("socketUrl", socketUrl);
      const ws = new WebSocket(socketUrl);

      ws.onmessage = function (e) {
        console.log(
          `${new Date().toLocaleString()} >>>>> 收到消息 ${e.data}`,
          state.ws
        );
        let msg = JSON.parse(e.data);
        if (msg.messageType != 0 * 1) {
          Notification({
            type: "info",
            title: "新消息",
            message: e.data,
            position: "top-right",
            duration: 3000,
            showClose: true,
          });
        }
      };

      ws.onclose = function () {
        console.log(`${new Date().toLocaleString()} >>>>> 连接已关闭`);
        // 如果是主动断开，设置标志
        if (state.isActiveDisconnect) {
          return;
        }
        // 尝试重新连接
        dispatch("reconnectWebSocket");
      };

      ws.onopen = function () {
        console.log(`${new Date().toLocaleString()} >>>>> 连接成功`, ws);
        Notification({
          type: "success",
          title: "成功",
          message: "进入大厅",
          position: "top-right",
          duration: 3000,
          showClose: true,
        });
        // 保存 WebSocket 连接信息
        commit("SET_WS", ws);
        // 重置主动断开标志
        commit("SET_IS_ACTIVE_DISCONNECT", false);
        // // 在这里调用 sendWebSocketMessage，确保 state.ws 已经被正确设置
        // 开始心跳检测
        dispatch("startHeartCheck");
      };

      ws.onerror = function (e) {
        console.log(`${new Date().toLocaleString()} >>>>> 数据传输发生异常`, e);
        Notification({
          type: "error",
          title: "错误",
          message: "会话连接异常，服务已断开",
          position: "top-right",
          duration: 3000,
          showClose: true,
        });
      };
    }
  },

  // 发送消息
  sendWebSocketMessage({ state }, msg) {
    console.log(
      `${new Date().toLocaleString()} >>>>> 发送消息：${JSON.stringify(msg)}`
    );
    state.ws.send(JSON.stringify(msg));
  },

  reconnectWebSocket({ dispatch }) {
    dispatch("clearWebSocket");
    // 递归调用，一直尝试重连
    setTimeout(() => {
      dispatch("startWebSocket");
    }, 6000);
  },

  clearWebSocket({ commit, state }) {
    if (state.ws) {
      state.ws.close();
      commit("CLEAR_WS");
    }
  },

  // 开启心态检测
  startHeartCheck({ commit, dispatch, state }) {
    console.log(`${new Date().toLocaleString()} >>>>> 开始心跳检测`, state.ws);
    // 清除之前的计时器
    dispatch("clearHeartCheckTimer");

    // 心跳发送的消息
    let data = {
      messageType: 0, //表示心跳消息
    };
    // 创建新的计时器
    dispatch("sendWebSocketMessage", data);
    const timer = setInterval(() => {
      if (!state.ws || state.ws.readyState !== WebSocket.OPEN) {
        console.log(
          `${new Date().toLocaleString()} >>>>> 心跳检测失败,触发重连`,
          state.ws
        );
        dispatch("reconnectWebSocket");
      } else {
        console.log(
          `${new Date().toLocaleString()} >>>>> 心跳正常,继续下一次心跳检测`,
          state.ws
        );
        dispatch("sendWebSocketMessage", data);
      }
    }, 1000 * 10);
    commit("SET_HEART_CHECK_TIMER", timer);
  },

  clearHeartCheckTimer({ commit, state }) {
    const timer = state.heartCheckTimer;
    timer && clearInterval(timer);
    commit("SET_HEART_CHECK_TIMER", null);
  },

  // 主动断开连接的方法
  activeDisconnect({ commit }) {
    commit("SET_IS_ACTIVE_DISCONNECT", true);
    if (state.ws) {
      const timer = state.heartCheckTimer;
      timer && clearInterval(timer);
      commit("SET_HEART_CHECK_TIMER", null);
      state.ws.close();
    }
  }
};

export default {
  state,
  mutations,
  actions,
};
