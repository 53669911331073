<template>
  <div class="popupMain fbox fbox-acenter fbox-jcenter" v-if="dark">
    <!-- 遮罩样式 -->
    <div class="shade" @click="dark = false"></div>
    <!-- 内容区域 -->
    <div class="popupContent">
      <div class="con">
        <div class="title f20 fontw tc">提示</div>
        <div class="text f16">有新的制作工具版本请前往常用工具页面下载！</div>
        <el-button
          type="primary"
          size="small"
          style="width: 100%; font-size: 16px"
          @click="navigator"
          >确定</el-button
        >
      </div>
      <i class="el-icon-circle-close" @click="dark = false"></i>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dark: false,
    };
  },
  mounted() {},
  methods: {
    //跳转到常用工具页面
    navigator(){
        this.dark = false;
        this.$router.push("/Tool")
    }
  },
};
</script>
<style lang="scss">
.popupMain {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  .shade {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
  .popupContent {
    position: relative;
    .con {
      width: 300px;
      height: auto;
      background: #fff;
      border-radius: 10px;
      padding: 15px;
      .title {
        color: #333;
        margin-bottom: 15px;
      }
      .text {
        color: #333;
        line-height: 22px;
        margin-bottom: 15px;
      }
    }
    i {
      display: block;
      text-align: center;
      font-size: 32px;
      color: #fff;
      margin-top: 10px;
      cursor: pointer;
    }
  }
}
</style>
