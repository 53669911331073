import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";
import Print from "vue-print-nb";

import "@/assets/fontFamily/font.css";

// 初始化样式
// import "normalize.css/normalize.css";
import "@/common/normalize.css";
import "@/common/iconfont.scss";

Vue.prototype.$Swiper = Swiper;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Print);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
