// login.js

const state = {
    // 当前登录用户信息
    info: null,
    // 当前登录用户的角色
    roles: [],
};

const getters = {

};

const mutations = {
    // 设置登录用户的信息
    setUserInfo(state, info) {
        state.info = info;
    },
    // 设置登录用户的角色
    setRoles(state, roles) {
        state.roles = roles;
    }
};
const actions = {
    /**
     * 更新用户信息
     */
    setInfo({ commit }, value) {
        commit('setUserInfo', value);
    },
};
// 注意和仓库的区别
const store = {
    // namespaced用于在全局引用此文件里的方法时标识这一个的文件名，使得让人明白这些数据来自哪个仓库
    // 即当你需要在别的文件里面使用子仓库(mapStates、mapGetters、mapActions)时，里面的方法需要注明来自哪一个模块的方法
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
export default store;
